import React from 'react'
import { render } from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './reducers/index'
import thunk from 'redux-thunk'
import { unregister } from './serviceWorker'

unregister()

const store = createStore(rootReducer, applyMiddleware(thunk))

render(
  <Provider store={store}>
    <BrowserRouter>
      <App/>
      {/*  <a href="javascript:void(Tawk_API.toggle())">
        <p className={'tawkBtnMain'}>
          <img src="/images/chat.svg" className={'tawkBtn'}></img>
          <span>Live Chat</span>
        </p>
      </a>*/}
    </BrowserRouter>
  </Provider>,
  document.querySelector('#root'),
)
