import React from 'react'
import Routes from './Routes'
import {connect} from 'react-redux'
//import "https://blog.1stopwash.com/wp-content/themes/consultix/css/react.css?ver=5.3.6";
/*
TODO removed for speed

import {css} from '@emotion/core'
import {ClipLoader} from "react-spinners";
const override = css`
  position: absolute; top: 200px;
`;
*/

class App extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.isLoading ?
                    <div className={'loader_outer_view'}>
                        {/*<ClipLoader
                            css={override}
                            size={150}
                            color={"#00af66"}
                            loading={this.props.isLoading}
                        />*/}
                        <div className={'loader_inner'}>
                            <img src={'/images/newLoader.svg'}/>

                        </div>

                    </div> : null}

                <Routes/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    isLoading: global.isLoading,
})
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)

