import React from 'react'
import loadable from '@loadable/component'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    withRouter,
} from 'react-router-dom'

// import {  OrderLayout, SubscriptionPack, ServiceLayoutRoute } from './layouts/index'
// import { Layout, CustomerLayout } from './layouts/layout'

//<editor-fold desc="Old Imports Styles">
// import ScrollToTop from './scrollToTop'
// import PriceEstimator from './containers/priceEstimator/index'
// import PlaceOrder from './containers/placeOrder/index'
// import OrderResponse from './containers/placeOrder/orderResponse'
// import Home from './containers/Home/index'
// import Login from './containers/authPages/login'
// import Signup from './containers/authPages/signup'
// import HowItWorks from './containers/pages/howItWorks'
// import AboutUs from './containers/pages/aboutUs'
// import GernalService from './containers/pages/gernalService'
// import sub from './containers/pages/sub'
// import OurStory from './containers/pages/ourStory'
// import LaundryNearMe from './containers/pages/laundryNearMe'
// import LaundryServiceNearMe from './containers/pages/laundryServiceNearMe'
// import DryCleaningPickUp from './containers/pages/dryCleaningPickUp'
// import DuvetCleaning from './containers/pages/duvetCleaning'
// import CurtainCleaning from './containers/pages/curtainCleaning'
// import EcoDryCleaningDelivery from './containers/pages/ecoDryCleaningDelivery'
// import ContactUs from './containers/pages/contactUs'
// import GreenCommitment from './containers/pages/greenCommitment'
// import BusinessLaundry from './containers/pages/businessLaundry'
// import Mission from './containers/pages/misson'
// import TermsConditions from './containers/pages/terms-conditions'
// import PrivacyPolicy from './containers/pages/privacy-policy'
// import RepairsAndAlterations from './containers/pages/repairsAndAlterations'
// import BedLinen from './containers/pages/bedLinen'
// import Laundry from './containers/pages/laundryNew'
// import ShirtService from './containers/pages/shirtService'
// import TrainerService from './containers/pages/trainerService'
// import EcoDryCleaning from './containers/pages/ecoDryCleaning'
// import IroningService from './containers/pages/IroningService'
// import LeatherFur from './containers/pages/leatherFur'
// import Household from './containers/pages/Household'
// import Faq from './containers/pages/faq'
// import AirbnbLinen from './containers/pages/airbnbLinen'
// import Restaurant from './containers/pages/restaurant'
// import TowelHire from './containers/pages/towelHire'
// import WhyUs from './containers/pages/whyUs'
// import Profile from './containers/customer/profile'
// import PaymentMethod from './containers/customer/paymentMethod'
// import Cleaning from './containers/customer/cleaning'
// import Delivery from './containers/customer/delivery'
// import OrderHistory from './containers/customer/orderHistory'
// import SubscribePack from './containers/pages/subscribePack'
// import StopWashRepeat from './containers/customer/stopWashRepeat'
// import Subscription from './containers/subscription'
// import SubscriptionResponse from './containers/subscription/SubscriptionResponse'
// import StopWashGo from './containers/customer/stopWashGo'
// import ForgotPassword from './containers/authPages/forgotPassword'
// import GoSubscribePack from './containers/pages/goSubscribePack'
// import GoIndex from './containers/subscription/goIndex'
// import GoSubscriptionResponse from './containers/subscription/goSubscriptionResponse'
//</editor-fold>

//<editor-fold desc="Loadable Components">
const ScrollToTop = loadable(() => import('./scrollToTop'))
const PriceEstimator = loadable(() => import('./containers/priceEstimator/index'))
const PlaceOrder = loadable(() => import('./containers/placeOrder/index'))
const OrderResponse = loadable(() => import('./containers/placeOrder/orderResponse'))
const Home = loadable(() => import('./containers/Home/index'))
const Login = loadable(() => import('./containers/authPages/login'))
const Signup = loadable(() => import('./containers/authPages/signup'))
const HowItWorks = loadable(() => import('./containers/pages/howItWorks'))
const AboutUs = loadable(() => import('./containers/pages/aboutUs'))
const GernalService = loadable(() => import('./containers/pages/gernalService'))
const sub = loadable(() => import('./containers/pages/sub'))
const OurStory = loadable(() => import('./containers/pages/ourStory'))
const LaundryNearMe = loadable(() => import('./containers/pages/laundryNearMe'))
const LaundryServiceNearMe = loadable(() => import('./containers/pages/laundryServiceNearMe'))
const DryCleaningPickUp = loadable(() => import('./containers/pages/dryCleaningPickUp'))
const DuvetCleaning = loadable(() => import('./containers/pages/duvetCleaning'))
const CurtainCleaning = loadable(() => import('./containers/pages/curtainCleaning'))
const EcoDryCleaningDelivery = loadable(() => import('./containers/pages/ecoDryCleaningDelivery'))
const ContactUs = loadable(() => import('./containers/pages/contactUs'))
const GreenCommitment = loadable(() => import('./containers/pages/greenCommitment'))
const BusinessLaundryOld = loadable(() => import('./containers/pages/businessLaundryOld'))
const BusinessLaundry = loadable(() => import('./containers/pages/businessLaundry'))
const Mission = loadable(() => import('./containers/pages/misson'))
const TermsConditions = loadable(() => import('./containers/pages/terms-conditions'))
const PrivacyPolicy = loadable(() => import('./containers/pages/privacy-policy'))
const RepairsAndAlterations = loadable(() => import('./containers/pages/repairsAndAlterations'))
const BedLinen = loadable(() => import('./containers/pages/bedLinen'))
const Laundry = loadable(() => import('./containers/pages/laundryNew'))
const ShirtService = loadable(() => import('./containers/pages/shirtService'))
const TrainerService = loadable(() => import('./containers/pages/trainerService'))
const EcoDryCleaning = loadable(() => import('./containers/pages/ecoDryCleaning'))
const IroningService = loadable(() => import('./containers/pages/IroningService'))
const LeatherFur = loadable(() => import('./containers/pages/leatherFur'))
const Household = loadable(() => import('./containers/pages/Household'))
const Faq = loadable(() => import('./containers/pages/faq'))
const AirbnbLinen = loadable(() => import('./containers/pages/airbnbLinen'))
const Restaurant = loadable(() => import('./containers/pages/restaurant'))
const TowelHire = loadable(() => import('./containers/pages/towelHire'))
const WhyUs = loadable(() => import('./containers/pages/whyUs'))
const Profile = loadable(() => import('./containers/customer/profile'))
const PaymentMethod = loadable(() => import('./containers/customer/paymentMethod'))
const Cleaning = loadable(() => import('./containers/customer/cleaning'))
const Delivery = loadable(() => import('./containers/customer/delivery'))
const OrderHistory = loadable(() => import('./containers/customer/orderHistory'))
const SubscribePack = loadable(() => import('./containers/pages/subscribePack'))
const StopWashRepeat = loadable(() => import('./containers/customer/stopWashRepeat'))
const Subscription = loadable(() => import('./containers/subscription'))
const SubscriptionResponse = loadable(() => import('./containers/subscription/SubscriptionResponse'))
const StopWashGo = loadable(() => import('./containers/customer/stopWashGo'))
const ForgotPassword = loadable(() => import('./containers/authPages/forgotPassword'))
const GoSubscribePack = loadable(() => import('./containers/pages/goSubscribePack'))
const GoIndex = loadable(() => import('./containers/subscription/goIndex'))
const GoSubscriptionResponse = loadable(() => import('./containers/subscription/goSubscriptionResponse'))

const InnerLayout = loadable(() => import('./layouts/inc/innerLayout'))
const OrderLayout = loadable(() => import('./layouts/inc/orderLayout'))
const ServiceLayoutRoute = loadable(() => import('./layouts/inc/serviceLayout'))
const SubscriptionPack = loadable(() => import('./layouts/inc/subscriptionLayout'))
const AuthRoute = loadable(() => import('./layouts/inc/authLayout'))
const ProtectedRoute = loadable(() => import('./layouts/inc/protectedLayout'))
const LandingPageLayout = loadable(() => import('./layouts/inc/landingPageLayout'))


const Blogs = loadable(() => import('./containers/blogs/blogs'))
const BlogPage = loadable(() => import('./containers/blogs/singleBlogs'))

//</editor-fold>

const isLoggedIn = localStorage.getItem('token') ? true : false
const loginUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

/*

class ProtectedRoute extends React.Component {
  render () {
    const {component: Component, ...props} = this.props
    return (
      <Route
        {...props}
        render={props => (
          <CustomerLayout>
            {loginUser ?
             <Component {...props}  exact /> :
             <Redirect to='/login' exact />}
          </CustomerLayout>
        )}
       exact />
    )
  }
}

class AuthRoute extends React.Component {
  render () {
    const {component: Component, ...props} = this.props
    return (
      <Route
        {...props}
        render={props => (
          <Layout>
            {
              !isLoggedIn ?
              <Component {...props}  exact /> :
              <Redirect to='/home' exact />
            }
          </Layout>
        )}
       exact />
    )
  }
}

*/

class Routes extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Router>
                    <ScrollToTop/>
                    <Switch>
                        <Redirect strict from={"/home/"} to={"/"}/>
                        {/*<Redirect strict from={"/contact-us/"} to={"/contact-us"}/>*/}

                        {/*  <InnerLayout exact path="/">
                            <Redirect to="/home" exact/>
                        </InnerLayout>*/}
                        {/*<InnerLayout exact path="/" component={Home}/>*/}
                        {/*<InnerLayout path="/home" component={Home} exact/>*/}

                        <InnerLayout exact path="/">
                            <Redirect to="/place-order" exact/>
                        </InnerLayout>

                        <InnerLayout exact path="/home">
                            <Redirect to="/place-order" exact/>
                        </InnerLayout>

                        <LandingPageLayout path="/price-estimator" component={PriceEstimator} exact/>
                        <OrderLayout path="/place-order" component={PlaceOrder} exact/>
                        <InnerLayout path="/order-success" component={OrderResponse} exact/>
                        {/*  <InnerLayout path="/how-it-works" component={HowItWorks} exact/>
                        <InnerLayout path="/about-us" component={AboutUs} exact/>

                        <InnerLayout path="/our-story" component={OurStory} exact/>
                        <InnerLayout path="/contact-us" exact component={ContactUs}/>


                        <InnerLayout path="/green-commitment" component={GreenCommitment} exact/>
                        <InnerLayout path="/sub" component={sub} exact/>
                        <InnerLayout path="/business-laundry" component={BusinessLaundry} exact/>


                        <InnerLayout path="/mission" component={Mission} exact/>
                        <InnerLayout path="/faq" component={Faq} exact/>
                        <InnerLayout path="/airbnb-linen-hire" component={AirbnbLinen} exact/>
                        <InnerLayout path="/restaurant-laundry-services-london" component={Restaurant} exact/>
                        <InnerLayout path="/restaurant-laundry-services" component={Restaurant} exact/>
                        <InnerLayout path="/towel-hire" component={TowelHire} exact/>
                        <InnerLayout path="/towel-hire-laundry-london" component={TowelHire} exact/>
                        <InnerLayout path="/terms-conditions" component={TermsConditions} exact/>
                        <InnerLayout path="/privacy-policy" component={PrivacyPolicy} exact/>
                        <InnerLayout path="/why-us" component={WhyUs} exact/>

                        <InnerLayout path="/1stopGo" component={GoSubscribePack} exact/>
                        <InnerLayout path="/complete/plan" component={SubscriptionResponse} exact/>
                        <InnerLayout path="/complete/go" component={GoSubscriptionResponse} exact/>
                        <InnerLayout path="/blog" component={Blogs} exact/>*/}

                        {/*Landing pages start*/}

                        {/* <LandingPageLayout path="/Laundry-and-dry-cleaning-Near-You" component={DryCleaningPickUp}
                                           exact/>
                        <LandingPageLayout path="/Green-Dry-Cleaning-Service-Near-You"
                                           component={EcoDryCleaningDelivery} exact/>
                        <LandingPageLayout path="/Duvet-Cleaning-Service" component={DuvetCleaning} exact/>
                        <LandingPageLayout path="/Curtain-Dry-Cleaning-Service-Near-You" component={CurtainCleaning}
                                           exact/>
                        <LandingPageLayout path="/dry-cleaners-near-you" component={LaundryNearMe} exact/>
                        <LandingPageLayout path="/laundry-service-near-you" component={LaundryServiceNearMe} exact/>*/}

                        {/*Landing pages end*/}


                        {/*<ServiceLayoutRoute path="/services/repairs-and-alterations" component={RepairsAndAlterations}
                                            exact/>
                        <ServiceLayoutRoute path="/services/laundry" component={Laundry} exact/>
                        <ServiceLayoutRoute path="/services/shirt-service" component={ShirtService} exact/>
                        <ServiceLayoutRoute path="/services/trainer-service" component={TrainerService} exact/>
                        <ServiceLayoutRoute path="/services/dry-cleaning" component={EcoDryCleaning} exact/>
                        <ServiceLayoutRoute path="/services/ironing-service" component={IroningService} exact/>
                        <ServiceLayoutRoute path="/services/bed-linen-and-duvet-cleaning" component={BedLinen} exact/>
                        <ServiceLayoutRoute path="/services/leather-suede-fur-cleaning-service" component={LeatherFur}
                                            exact/>
                        <ServiceLayoutRoute path="/services/cushion-rug-curtain-cleaning" component={Household} exact/>
                        <ServiceLayoutRoute exact path="/services" component={GernalService}/>

*/}

                        {/*<InnerLayout path="/subscribe-pack" component={SubscribePack} exact/>*/}

                        <SubscriptionPack path="/start/plan" component={Subscription} exact/>
                        {/*<SubscriptionPack path="/start/go" component={GoIndex} exact/>*/}


                        <AuthRoute path="/login" component={Login} exact/>
                        <AuthRoute path="/forgot-password" component={ForgotPassword} exact/>
                        <AuthRoute path="/customer/start" component={Signup} exact/>


                        <ProtectedRoute path="/profile" component={Profile} exact/>
                        <ProtectedRoute path="/payment-method" component={PaymentMethod} exact/>
                        <ProtectedRoute path="/order-history" component={OrderHistory} exact/>
                        <ProtectedRoute path="/preferences-delivery" component={Delivery} exact/>
                        <ProtectedRoute path="/preferences-cleaning" component={Cleaning} exact/>
                        <ProtectedRoute path="/subscription-repeat" component={StopWashRepeat} exact/>
                        <ProtectedRoute path="/subscription-go" component={StopWashGo} exact/>

                        {/*<InnerLayout path="/:catName/:id" component={BlogPage} exact/>*/}

                        <Route path="*" exact>
                            <Redirect to="/"/>
                        </Route>

                    </Switch>
                </Router>
            </React.Fragment>
        )
    }
}

export default Routes


/*
Laundry-and-dry-cleaning-Near-You
Green-Dry-Cleaning-Service-Near-You
Duvet-Cleaning-Service
Curtain-Dry-Cleaning-Service-Near-You
dry-cleaners-near-you
laundry-service-near-you
*/
