const initialState = {
    slotId: "",
    currentUser: {},
    selectedAddress: {},
    pickupTime: {},
    deliveryTime: {},
    serviceList: [],
    isLoading: false,
    plan: '',
    planType: '',
    bagType: '',
    subscriptionId: ''
}

export default (state = initialState, action) => {
    switch (action && action.type) {
        case 'SET_CURRENT_USER':
            return {
                ...state,
                currentUser: action.user
            }

        case 'LOGOUT':
            return {
                ...state,
                currentUser: {}
            }

        case 'SET_ADDRESS_AND_TIME': {
            return {
                ...state,
                slotId: action.data.slotId,
                selectedAddress: action.data.address,
                pickupTime: action.data.pickupTime,
                deliveryTime: action.data.deliveryTime,
            }
        }
        case 'SET_SERVICES_LIST': {
            return {
                ...state,
                serviceList: action.serviceList
            }
        }
        case 'CHOOSE_PLAN': {
            return {
                ...state,
                plan: action.data.plan,
                planType: action.data.type,
                bagType: action.data.bagType ? action.data.bagType : 'stopBag'

            }
        }
        case 'SHOW_LOADER': {
            return {
                ...state,
                isLoading: true
            }
        }

        case 'HIDE_LOADER': {
            return {
                ...state,
                isLoading: false
            }
        }
        default:
            return state
    }
}
